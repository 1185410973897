import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'tools/i18n';
import useApplicationStore from '@services/stores/ApplicationStore';
import BankID from '@components/account/BankID';
import CallToActionButton from '@components/products/partials/CallToActionButton';
import NewportInput from '@components/forms/NewportInput';
import NewportForm from '@components/forms/NewportForm';
import If from '@components/misc/If';
import theme from '@components/theme';
import NewportSociety from '../../public/images/logo/newport-society.svg'
import useUserStore from '@services/stores/UserStore';
import { useState } from 'react';
import NewportDrawer from '@components/drawer';
import AccountSignup from '@components/account/AccountSignup';
import { ucfirst } from '@services/Utils';

const styles = {
    loginWrapper: {
        marginBottom: 80,
    },
    signupWrapper: {
        padding: '2rem',
        background: '#041e3a',
        margin: -20,
        marginTop: 'auto',
        marginBottom: -30,
        paddingBottom: '8rem',
        color: 'white',
        textAlign: 'center',
    },
    login: {
        maxWidth: 400,
        margin: 'auto',
        width: '100%',
        '& > h3': {
            marginTop: 0,
            textAlign: 'center',
        }
    },
    signup: {
        maxWidth: 400,
        margin: 'auto',
        marginTop: 50,
        width: '100%',
        '& > h3': {
            textAlign: 'center',
        }
    },
    or: {
        padding: '48px 0',
        textAlign: 'center',
        fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 12,
        '& > span': {
            position: 'relative',
            background: 'white',
            padding: '0 1rem',
            display: 'inline-flex',
            zIndex: 2,
        },
        '&:after': {
            content: '""',
            top: -10,
            display: 'block',
            position: 'relative',
            minHeight: '2px',
            background: theme.palette.primary.lightest,
            zIndex: 0
        }
    },
};

const useStyles = makeStyles(() => styles, { name: 'AccountLogin' });

export default function AccountLogin() {
    const { t } = useTranslation([ 'account' ]);
    const user = useUserStore();
    const newportService = useUserStore((state) => state.getService());
    const classes = useStyles();
    const app = useApplicationStore();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [timer, setTimer] = useState(null);
    const [openSignup, setOpenSignup] = useState(false);

    function toggleSignup() {
        setOpenSignup(!openSignup);
    }

    function supportsBankID() {
        const isDev = app.methods.isDevelopment();
        const items = [
            'newport.se',
        ];
        let supports = isDev;

        items.forEach(function (item) {
            if (location.host.includes(item)) {
                supports = true;
            }
        });

        return supports;
    }

    const handleEmailLogin = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        event.stopPropagation(); // Stop event propagation

        // Clear any previous timeout to reset the debounce
        if (timer) {
            clearTimeout(timer);
        }

        // Set a new timeout to debounce the login function
        const newTimer = setTimeout(async () => {
            // Ensure both email and password are provided
            if (!email?.length || !password?.length) {
                return;
            }

            try {
                const response = await newportService.login(email, password);

                // Handle validation errors (e.g., 422 Unprocessable Entity)
                if (response?.response?.status === 422) {
                    const payload = await response.response.json();
                    setErrors(payload.errors);
                    setErrorMessage(payload.message);
                    return;
                }


                // Handle general login failure (response status 'fail')
                if (response.status === 'fail') {
                    setErrorMessage(response.message);
                    return;
                }

                // Handle success response
                if (response.status === 'success') {
                    const loginResponse = response.data;
                    user.login(loginResponse.user, loginResponse.auth);
                }
            } catch (error) {
                // Handle any errors during the async request (network issues, etc.)
                setErrorMessage('An error occurred. Please try again later.');
                console.error(error);
            }
        }, 300); // debounce delay

        setTimer(newTimer);
    };

    return <>
        <section className={classes.loginWrapper}>
            <If condition={supportsBankID()}>
                <div className={classes.login}>
                    <h3>{t('login-bankid-title')}</h3>
                    <BankID></BankID>
                    <div className={classes.or}>
                        <span>{t('or-separator')}</span>
                    </div>
                </div>
            </If>
            <NewportForm className={classes.login}>
                <h3>{t('login-title')}</h3>
                <NewportInput
                    placeholder={t('general:email')}
                    className={classes.accountInputEmail}
                    type={'email'}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <NewportInput
                    placeholder={t('general:password')}
                    className={classes.accountInputPassword}
                    type={'password'}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <CallToActionButton
                    type={'submit'}
                    onClick={handleEmailLogin}
                    colorScheme={'bg-standard'}
                >
                    {t('account-login')}
                </CallToActionButton>
                <If condition={Object.keys(errors)?.length || errorMessage}>
                    <div className={classes.errors}>
                        {ucfirst(errorMessage)}
                    </div>
                </If>
            </NewportForm>
        </section>
        <section className={classes.signupWrapper}>
            <NewportForm className={classes.signup}>
                <NewportSociety style={{maxWidth: 256, margin: '0 auto'}}></NewportSociety>
                <p>{t('account-login-drawer-description')}</p>
                <CallToActionButton colorScheme={'bg-light'} onClick={() => toggleSignup()}>
                    {t('account-signup')}
                </CallToActionButton>
            </NewportForm>

            <NewportDrawer open={openSignup} close={() => toggleSignup()}>
                <AccountSignup></AccountSignup>
            </NewportDrawer>
        </section>
    </>
}
