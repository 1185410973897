import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'tools/i18n';
import useUserStore from '../../services/stores/UserStore';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import CallToActionButton from '@components/products/partials/CallToActionButton';
import BankIdIcon from '@public/images/icons/bankid/BankID_logo_white.svg';
import theme from '@components/theme';
import If from '@components/misc/If';

const styles = {
    authenticationWrapper: {
        position: 'relative',
        width: '100%',
        maxWidth: 400,
        margin: 'auto',
    },
    waitingForBankAppSigning: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 16,
        position: 'absolute',
        zIndex: 5,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#ffffff',
        height: '100%',
        width: '100%',
        userSelect: 'none',
    },
    bankIdLoadingIcon: {
        position: 'relative',
    },
    bankIdInvert: {
        position: 'absolute',
        filter: 'invert(1)',
        height: 32,
        width: 32,
        top: 16,
        left: 16,
        transform: 'scale(1.75)'
    },
    qrCodeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 20,
        paddingTop: 0,
    },
    qrCodeImageWrapper: {
        position: 'relative',
        height: 164,
        width: 164,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: 128,
            width: 128,
        },
        '& > img': {
            height: '100%',
            width: '100%',
            mixBlendMode: 'screen',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundColor: theme.palette.primary.dark,
            zIndex: -1,
        },
    },
    qrCodeLoading: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: '#ffffff',
    },
    bankIdButtonText: {
        display: 'grid',
        gridTemplateColumns: '24px 1fr',
        gap: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        textAlign: 'center',
        minHeight: 54,
        background: '#fafafa',
        padding: 16,
        color: '#222',
    },
};

const useStyles = makeStyles(() => styles, { name: 'AccountLogin' });

export default function BankID() {
    const classes = useStyles();
    const { t } = useTranslation([ 'account' ]);
    const user = useUserStore();
    const services = user.getService();
    const [ orderReference, setOrderReference ] = useState(null);
    const pollingRef = useRef(null);
    const [ pollingPending, setPollingPending ] = useState(false);
    const [ appUrl, setAppUrl ] = useState(null);
    const [ payload, setPayload ] = useState({
        bankIdStatus: null,
        qrCode: null,
        hintCode: null,
        message: null,
    });

    function clear() {
        clearTimeout(pollingRef.current);
        pollingRef.current = null;
        setPayload({ bankIdStatus: null, qrCode: null, hintCode: null, message: '' });
    }

    function isWaitingForAppSigning() {
        return payload?.hintCode === 'userSign';
    }

    function fancyBankIdLoader() {
        return <>
            <div className={classes.bankIdLoadingIcon}>
                <CircularProgress size={64}></CircularProgress>
                <BankIdIcon className={classes.bankIdInvert}></BankIdIcon>
            </div>
        </>
    }

    async function poll(orderReferenceId) {
        if (!pollingPending && orderReferenceId) {
            setPollingPending(true);

            try {
                const json = await services.fetchBankIdSessionStatus(orderReferenceId);
                setAppUrl(`bankid:///?autostarttoken=${json.autoStartToken}&redirect=null`);

                if (json.status !== 'success') {
                    clear();
                    setPayload(prev => ({ ...prev, message: json.message }));
                    init();
                    return;
                }

                const payload = {
                    bankIdStatus: json.data.bankIDStatus,
                    qrCode: json.qrCode,
                    hintCode: json.data.hintCode,
                    message: json.message,
                };

                setPayload(payload);

                const status = json?.data?.bankIDStatus;

                if ([ 'error', 'failed' ].includes(status)) {
                    clear();
                    return;
                }

                if (status === 'complete') {
                    clear();
                    const auth = json.data.auth;
                    const userPayload = json.data.user;
                    user.login(userPayload, auth);
                    return;
                }

                const pollingTimer = isWaitingForAppSigning() ? 1000 : 5000;

                pollingRef.current = setTimeout(() => poll(orderReferenceId), pollingTimer);
            } finally {
                setPollingPending(false);
            }
        }
    }

    async function init() {
        try {
            const json = await services.startBankIdSession();

            if ([ 'error', 'fail' ].includes(json.status)) {
                setPayload(prev => ({ ...prev, bankIdStatus: json.status, message: 'bankid-failed' }));
                clear();
                return;
            }

            if (json.data.orderRef) {
                const id = json.data.orderRef;
                setOrderReference(id);
                poll(id);
            }
        } catch (error) {
            console.error('Error initializing BankID:', error);
        }
    }

    useEffect(() => {
        init();
        return () => {
            clear();
        };
    }, []);

    function renderQrCode() {
        let qrElement = <div className={classes.qrCodeLoading}>
            {fancyBankIdLoader()}
        </div>;

        if (payload.qrCode) {
            qrElement = <img src={payload.qrCode} alt={payload.message} />;
        }

        return <>
            <section className={classes.qrCodeWrapper}>
                <div className={classes.qrCodeImageWrapper}>
                    {qrElement}
                </div>
            </section>
            <section>
                <CallToActionButton href={appUrl}>
                    <div className={classes.bankIdButtonText}>
                        <BankIdIcon style={{height: 24, width: 24}}></BankIdIcon>
                        {t('account:open-bankid-app')}
                    </div>
                </CallToActionButton>
                <div className={classes.message}>
                    {t(payload.message)}
                </div>
            </section>
        </>;
    }

    return (
        <div className={classes.authenticationWrapper}>
            {renderQrCode()}
            <If condition={isWaitingForAppSigning()}>
                <div className={classes.waitingForBankAppSigning}>
                    {fancyBankIdLoader()}
                    <Typography>{t('account:waiting-for-bank-app-signing')}</Typography>
                </div>
            </If>
        </div>
    );
}
