import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Badge } from '@material-ui/core';
import Like from '../../public/images/icons/heart-ultrathin.svg';
import theme from '../theme';
import { useTranslation } from 'tools/i18n';
import { useRouter } from 'next/router';
import NewportDrawer from '../drawer';
import useUserStore from '../../services/stores/UserStore';
import StorefrontApiService from '../../services/StorefrontApiService';
import FavoriteItem from '../products/wishlist/FavoriteItem';
import CallToActionButton from '../products/partials/CallToActionButton';
import useCartStore from '@services/stores/CartStore';
import useSessionStore from '@services/stores/SessionStore';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import WishlistActionListing from '@components/header/favorites/WishlistActionListing';
import useApplicationStore from '@services/stores/ApplicationStore';
import ProductNotify from '@components/misc/notifications/ProductNotify';

const styles = {
    wrapper: {
        display: 'flex',
    },
    listings: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    trigger: {
        cursor: 'pointer',
        padding: '10px 7px',
        height: 40,
        background: 'transparent',
        border: 'none',
    },
    icon: {
        display: 'block',
        width: 20,
        height: 20,
        color: '#000000',
        strokeWidth: 0.5,
        fill: 'transparent',
        position: 'relative',
    },
    badge: {
        verticalAlign: 'initial',
    },
    count: {
        color: theme.palette.primary.background,
        padding: '0 4px',
        fontSize: 11,
        backgroundColor: '#000',
        position: 'absolute',
        top: -3,
        right: -5,
        transform: 'scale(0.5)',
        '.xmas &': {
            background: theme.palette.primary.background,
            color: '#000',
        },
    },
    drawerTitle: {
        fontSize: '14px',
        fontWeight: 600,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: 280,
        width: '100%',
    },
    loginWrapper: {
        padding: '3rem 2rem',
        background: '#fafafa',
    },
    addRemoveLabels: {
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '80px 24px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '10px 0',
        marginTop: 3,
        fontSize: 14,
        borderBottom: '1px solid #f0f0f0',
        minHeight: 56,
        '& .name': {
            lineHeight: '22px',
            color: 'inherit',
            marginRight: '4px',
            cursor: 'pointer',
        },
        '& .count': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '8px',
            marginRight: '8px',
            fontWeight: 500,
        },
        '& .title': {
            display: 'flex',
            '.xmas &': {
                color: '#000 !important',
            },
        },
        '&.active': {
            fontWeight: 600,
        },
        '&.new': {
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontSize: 12,
            backgroundColor: theme.palette.primary.main + '!important',
            color: theme.palette.primary.background,
        },
        '&.empty': {
            flexDirection: 'column',
            color: '#555',
            textAlign: 'center',
            justifyContent: 'center',
            '&:hover': {
                background: 'unset',
            },
        },
        '& a': {
            color: 'inherit',
        },
    },
    active: {
        '& .name': {
            fontWeight: 500,
        },
    },
    activeIcon: {
        marginRight: 5,
        fontSize: '1rem',
        cursor: 'pointer',
    },
    actionWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
        marginTop: 10,
        paddingTop: '20px',
        '& > span ': {
            margin: 'auto',
        },
        '& > span a': {
            color: 'inherit',
            fontSize: 12,
            marginRight: 0,
        },
    },
    tabsGroup: {
        width: '100%',
        margin: 'auto auto 20px',
        display: 'flex',
        justifyContent: 'center',
    },
    tabButton: {
        margin: 0,
        border: 'none',
        padding: '15px 0 10px',
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
        fontSize: 11,
        letterSpacing: 1.5,
        textTransform: 'uppercase',
        width: 'auto',
        flexGrow: 1,
        cursor: 'pointer',
        color: 'black',
        background: `${theme.palette.primary.lightest}45`,
        borderBottom: `2px solid ${theme.palette.primary.lightest}7a`, // with transparency
        '&:hover': {
            borderBottom: '2px solid #222',
        },
        '&.active': {
            borderBottom: '2px solid #000',
            background: `${theme.palette.primary.lightest}`,
        },
    },
    wishlistFavoriteChoice: {
        padding: '1rem',
        background: '#f5f5f5',
        width: '100%',
        textAlign: 'center',
    },
    confirm: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 8,
    },
};

const useStyles = makeStyles(() => styles, { name: 'Favorites' });

export default function Favorites() {
    const router = useRouter();
    const classes = useStyles();
    const { t } = useTranslation();

    const { toggleDrawer } = useApplicationStore((state) => state.methods);
    const open = useApplicationStore((state) => state.modals.wishlistDrawerOpen);
    const accountDrawerOpen = useApplicationStore((state) => state.modals.accountDrawerOpen);
    const [ triggeredAccountDrawer, setTriggeredAccountDrawer ] = useState(false);

    const [ activeTab, setActiveTab ] = useState(0);
    const [ highlighted, setHighlighted ] = useState(0);
    const [ wishlistType, setWishlistType ] = useState('standard');
    const [ wishlistProductPickToggle, setWishlistProductPickToggle ] = useState(false);
    const [ showMoveFavoritesToWishlist, setShowMoveFavoritesToWishlist ] = useState(false);
    const [ favoriteProducts, setFavoriteProducts ] = useState([]);
    const [ confirmClear, setConfirmClear ] = useState(false);
    const [ showMovePick, setShowMovePick ] = useState(false);

    const sessionMethods = useSessionStore((state) => state.methods);
    const cartMethods = useCartStore((state) => state.methods);
    const user = useUserStore();
    const wishlists = useUserStore((state) => state.wishlists);
    const wishlistProducts = useUserStore((state) => state.wishlistProducts);
    const favorites = useUserStore((state) => state.favorites);
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);

    useEffect(async () => {
        let path = router.asPath;
        if (path?.includes('#create-wishlist')) {
            if (path.includes('#create-wishlist-xmas')) {
                setWishlistType('christmas');
            }

            toggleDrawer('wishlistDrawerOpen', true);
            toggleTab(1);
            openAccount(1, true);
        } else {
            toggleDrawer('wishlistDrawerOpen', false);
        }
    }, [ router.asPath ]);

    useEffect(() => {
        window.addEventListener('toggle-wishlist-product', handleToggleWishlistProduct);
        window.addEventListener('user-logged-in', handleUserLoggedIn);

        return () => {
            window.removeEventListener('toggle-wishlist-product', handleToggleWishlistProduct);
            window.removeEventListener('user-logged-in', handleUserLoggedIn);
        };
    }, []);


    useEffect(() => {
        setHighlighted(wishlistProducts.length + favoriteProducts.length);
    }, [ wishlistProducts, favoriteProducts ]);

    useEffect(() => {
        if (triggeredAccountDrawer && accountDrawerOpen === false) {
            setTriggeredAccountDrawer(false);
            toggleDrawer('wishlistDrawerOpen', true);
            setActiveTab(1);
        }
    }, [ accountDrawerOpen, triggeredAccountDrawer ]);

    useEffect(async () => {
        if (favorites.length) {
            const api = new StorefrontApiService();
            const response = await api.getProductByIds(favorites);

            if (response.status === 'success' && Array.isArray(response?.products)) {
                setFavoriteProducts(response?.products || []);
            }
        } else {
            setFavoriteProducts([]);
        }
    }, [ favorites ]);

    function handleUserLoggedIn() {
        if (location.hash.includes('wishlist')) {
            setTriggeredAccountDrawer(false);
            toggleDrawer('accountDrawerOpen', false);
            toggleDrawer('wishlistDrawerOpen', true);
        }
    }

    async function handleToggleWishlistProduct(event) {
        const {
            detail: {
                productId,
            },
        } = event;

        const api = new StorefrontApiService();
        const response = await api.getProductByIds([ productId ]);
        const app = useApplicationStore.getState()?.methods;

        if (response.status === 'success' && response.products.length === 1) {
            const product = response.products[0];
            const productElement = <ProductNotify product={product}>
                <CallToActionButton
                    style={{marginTop: 10}}
                    size={'small'}
                    colorScheme={'bg-light'}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        toggleModal()
                        toggleTab(1);
                        setShowMovePick(true);
                        app.removeNotification(productId);
                        setWishlistProductPickToggle(response.products[0]);
                    }}
                >
                    {t('add-to-wishlist-choice')}
                </CallToActionButton>
            </ProductNotify>;

            app.notify(null, {
                title: 'favorites-add-product',
                id: productId,
                components: productElement,
            });
        }
    }

    function toggleModal() {
        user.checkLoginStatus();

        if (!isLoggedIn) {
            toggleTab(0);
        }

        if (isLoggedIn && favorites.length === 0) {
            toggleTab(1);
        } else {
            toggleTab(0);
        }

        if (open) {
            // This is before closing
            resetState();
            if (window.location.hash.includes('wishlist')) {
                router.replace(window.location.pathname, { scroll: false });
            }
        }

        toggleDrawer('wishlistDrawerOpen', !open);
    }

    function openAccount(target, instant = false) {
        if ((open || instant) && target === 1 && !isLoggedIn && !triggeredAccountDrawer) {
            setTriggeredAccountDrawer(true);
            toggleDrawer('accountDrawerOpen', true);
            window.location.hash = 'wishlist-open';
        }
    }

    function toggleTab(target) {
        openAccount(target);
        setActiveTab(target);
    }

    function triggerClick(event) {
        event.preventDefault();
        event.stopPropagation();
        toggleModal();
        return false;
    }

    function resetState() {
        setShowMoveFavoritesToWishlist(false);
        setWishlistProductPickToggle(false);
        setShowMovePick(false);
        setConfirmClear(false);
        setHighlighted(0);
        setWishlistType('standard');
        setTriggeredAccountDrawer(false);
    }

    function renderFavorites() {
        async function handleAllAddToCart() {
            if (open) {
                toggleModal();
            }
            await cartMethods.addItemsToCart(favorites);
        }

        function handleMoveToWishlist() {
            resetState();
            toggleTab(1);
            setShowMoveFavoritesToWishlist(true);
        }

        function renderClearAll() {
            let confirm = null;

            if (confirmClear) {
                confirm = <div>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}>{t('favorite-are-you-sure')}</h4>
                    <section className={classes.confirm}>
                        <CallToActionButton
                            size={'small'}
                            colorScheme={'bg-light'}
                            onClick={() => user.favoriteMethods.clearFavorites()}
                        >
                            {t('favorite-clear-list-continue')}
                        </CallToActionButton>
                        <CallToActionButton
                            size={'small'}
                            colorScheme={'bg-white'}
                            onClick={() => setConfirmClear(false)}
                        >
                            {t('cancel')}
                        </CallToActionButton>
                    </section>
                </div>;
            }

            return <section style={{ marginTop: '4rem' }}>
                <CallToActionButton
                    show={!confirmClear}
                    size={'small'}
                    colorScheme={'bg-white'}
                    onClick={() => setConfirmClear(true)}
                >
                    {t('favorite-clear-list')}
                </CallToActionButton>
                {confirm}
            </section>;
        }

        return <>
            <h3 className={classes.drawerTitle}>{t('favorite-wishlist-drawer-title')}</h3>

            {favoriteProducts.map((product) => {
                return (
                    <FavoriteItem key={product.id} product={product}></FavoriteItem>
                );
            })}

            {favoriteProducts.length === 0 && (
                <article className={[ classes.item, 'empty' ].join(' ')}>
                    <p>{t('no-favorites')}</p>
                </article>
            )}

            {favoriteProducts.length > 0 &&
                <div key={'key-actions'} className={[ classes.actionWrapper ].join(' ')}>
                    <CallToActionButton
                        size={'small'}
                        colorScheme={'bg-light'}
                        onClick={handleAllAddToCart}
                    >
                        {t('add-all-favorites-to-cart')}
                    </CallToActionButton>

                    <CallToActionButton
                        size={'small'}
                        colorScheme={'bg-light'}
                        onClick={handleMoveToWishlist}
                    >
                        {t('new-wishlist-from-favorites')}
                    </CallToActionButton>

                    {renderClearAll()}
                </div>
            }
        </>;
    }

    function renderCurrentProductChoice(product) {
        if (!product) {
            return null;
        }

        function renderList(show) {
            if (!show) {
                return null;
            }

            return <WishlistActionListing
                wishlistType={wishlistType}
                classes={classes}
                wishlists={wishlists}
                renderElement={(wishlist => {
                    let itemCount = wishlist?.items?.length ? wishlist?.items?.length : wishlist.items_count || 0;
                    let element = itemCount !== 0 ? <span className={'count'}>({itemCount})</span> : null;
                    return <>
                        {element}
                    </>;
                })}
                renderButton={(wishlist) => {
                    const exist = user.wishlistMethods.existsInWishlist(wishlistProductPickToggle.id, wishlist.id);

                    return <CallToActionButton
                        show
                        style={{
                            minWidth: '100px',
                            maxWidth: 'fit-content',
                            marginLeft: 'auto',
                            maxHeight: '46px',
                        }}
                        colorScheme={exist ? 'bg-light' : 'bg-white'}
                        size={'small'}
                        onClick={() => {
                            user.wishlistMethods.toggleWishlist(wishlistProductPickToggle.id, wishlist.id);
                            user.favoriteMethods.removeFromFavorites(wishlistProductPickToggle.id);
                        }}
                    >
                        <div className={classes.addRemoveLabels}>
                            {exist ? t('wishlist-remove-item') : t('wishlist-add-item')}
                            {exist ? <RemoveCircleOutline></RemoveCircleOutline> :
                                <AddCircleOutline></AddCircleOutline>}
                        </div>
                    </CallToActionButton>;
                }}
            />;
        }

        return <>
            <b style={{ marginBottom: '8px' }}>{t('add-to-wishlist-product-notice')}</b>
            <div style={{ width: '100%' }}>
                <FavoriteItem
                    showBuyButton={false}
                    onRemove={() => {
                        toggleModal();
                    }}
                    style={{ padding: '1rem', border: '4px solid #fafafa', borderRadius: '4px' }}
                    styleCard={{ border: 'none', marginBottom: 8 }}
                    product={product}
                ></FavoriteItem>
            </div>
            <section>
                <CallToActionButton
                    show
                    colorScheme={showMovePick ? 'bg-white' : 'bg-light'}
                    onClick={() => setShowMovePick(!showMovePick)}
                >
                    {t('add-to-wishlist-choice')}
                </CallToActionButton>
                {renderList(showMovePick)}
            </section>
        </>;
    }

    function renderMoveToWishlist(show) {
        if (!show) {
            return null;
        }

        return <>
            <div className={classes.wishlistFavoriteChoice}>
                {t('add-favorites-to-wishlist')}
            </div>
            <WishlistActionListing
                wishlistType={wishlistType}
                classes={classes}
                wishlists={wishlists}
                renderButton={(wishlist) => {
                    const exist = user.wishlistMethods.existsInWishlist(wishlistProductPickToggle.id, wishlist.id);

                    return <CallToActionButton
                        show
                        style={{
                            minWidth: '100px',
                            maxWidth: 'fit-content',
                            marginLeft: 'auto',
                            maxHeight: '46px',
                        }}
                        colorScheme={exist ? 'bg-light' : 'bg-white'}
                        size={'small'}
                        onClick={async () => {
                            setShowMoveFavoritesToWishlist(false);
                            await user.favoriteMethods.moveToList(favorites, wishlist.id);
                            user.favoriteMethods.clearFavorites();
                        }}
                    >
                        <div className={classes.addRemoveLabels}>
                            {exist ? t('wishlist-remove-item') : t('wishlist-add-item')}
                            {exist ? <RemoveCircleOutline></RemoveCircleOutline> :
                                <AddCircleOutline></AddCircleOutline>}
                        </div>
                    </CallToActionButton>;
                }}
            />
        </>;
    }

    function renderBadgeCounter(itemCount) {
        return <Badge style={{ marginRight: 16 }} badgeContent={itemCount} color={'primary'}></Badge>;
    }

    function renderWishlists() {
        if (!isLoggedIn) {
            return <article className={[ classes.item, 'empty' ].join(' ')}>
                {t('no-wishlist')}
            </article>;
        }

        function renderListings(show) {
            if (!show) {
                return null;
            }

            function getDiscoverProducts() {
                return sessionMethods.isSalesChannelByDomain('se') ? '/create-wishlist' : '/news';
            }

            return <WishlistActionListing
                wishlistType={wishlistType}
                wishlists={wishlists}
                classes={classes}
                renderElement={(wishlist) => {
                    let itemCount = wishlist?.items?.length ? wishlist?.items?.length : wishlist.items_count || 0;
                    return itemCount !== 0 ? renderBadgeCounter(itemCount) :
                        <a href={getDiscoverProducts()}>{t('favorite-wishlist-go-to-products')}</a>;
                }}
            ></WishlistActionListing>;
        }

        return (
            <>
                <section className={classes.listings}>
                    {renderCurrentProductChoice(wishlistProductPickToggle)}
                    {renderMoveToWishlist(showMoveFavoritesToWishlist)}
                    {renderListings(!showMoveFavoritesToWishlist && !wishlistProductPickToggle)}
                </section>

                {wishlists.length === 0 && (
                    <article className={[ classes.item, 'empty' ].join(' ')}>
                        <div>{t('no-wishlist')}.</div>
                    </article>
                )}


            </>
        );
    }

    function renderTabsContent() {
        return (
            <div className={[ classes.list ].join(' ')}>
                <div className={classes.tabsGroup}>
                    <button
                        className={[ classes.tabButton, activeTab === 0 ? 'active' : '' ].join(' ')}
                        onClick={() => {
                            toggleTab(0);
                        }}
                    >
                        {t('favorite-wishlist-drawer-tab-one-title')}
                        <Badge style={{ marginLeft: '18px' }} color={'primary'}
                               badgeContent={favorites.length || 0}></Badge>
                    </button>
                    <button
                        className={[ classes.tabButton, activeTab === 1 ? 'active' : '' ].join(' ')}
                        onClick={() => {
                            toggleTab(1);
                        }}
                    >
                        {t('favorite-wishlist-drawer-tab-two-title')}
                        <Badge style={{ marginLeft: '18px' }} color={'primary'}
                               badgeContent={wishlists.length || 0}></Badge>
                    </button>
                </div>

                {activeTab === 0 && renderFavorites()}
                {activeTab === 1 && renderWishlists()}
            </div>
        );
    }

    return (
        <>
            <div className={[ classes.wrapper, theme.mode ].join(' ')}>
                <button className={[ classes.trigger, 'favorites' ].join(' ')} onClick={e => {
                    triggerClick(e);
                }}>
                    <Badge badgeContent={(highlighted ? '•' : null)}
                           classes={{ root: classes.badge, badge: classes.count }}>
                        <Like width={20} className={classes.icon} />
                    </Badge>
                </button>

                <NewportDrawer anchor="right" open={open && !triggeredAccountDrawer} close={toggleModal}>
                    {renderTabsContent()}
                </NewportDrawer>
            </div>
        </>
    );
}
